.dashboard_content{
  padding:3em 0;

  .react-tabs__tab-panel{
    padding-top:2em;
  }

  
}

.dashboard_generals,.dashboard_orders{
  padding:3em 0;


  @media all and (max-width:768px){
    padding:3em 1em;
  }

  .row_title{
    margin-bottom:2em;
    background-color: #FFF;
    box-shadow: 6px 6px 10px -3px rgba(0,0,0,.1);
    padding:.3em;
    border-radius:.3em;
  }
  .col_action{
    margin-top:0 !important;
    display:flex;
    align-items: center;
    justify-content: flex-end;
  }


  .row_avatar{
    align-items: center;
    flex-direction: column;
    text-align: center;

    .avatar{
      width:10em;
      height:10em;
      object-fit:contain;
      margin-bottom:1em;
      border:solid 2px #ececec;
      border-radius:.4em;
    }

    .upload_avatar_input{
      display:none;
    }

    .upload_avatar_button{
      max-width:10em;
    }

    @media all and (max-width:768px){
      margin-bottom:2em;
    }

  }

  .row_label_title{
    font-size:1.3em;
    margin-bottom:1em;
  }

  .pass_request{
    padding:1em;
    background:#FFF;
    box-shadow: 6px 6px 10px -3px rgba(0,0,0,.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .thumb{
      width:25%;
      background-color: rgb(237, 237, 237);
      border-radius: .3em;
      padding:1em .3em;
    
      img{
        width:100%;
        height:8em;
        object-fit: contain;
      }
    }

    .infos{
      padding-left:2em;
      width:65%;
      display: flex;
      flex-direction: column;
    }
    a{
      background: $turquoise;
      text-decoration: none;
      padding:.3em 1em;
      color:#FFF;
      border-radius: .3em;
      display: inline-block;
      text-align: center;
    }

  }


}

.dashboard_orders{
  .row_sign{
    padding:0 1em;
  }
  .sigCanvas{
   // width:100%;
    border:solid 2px #d9d9d9;
  }

  .preview_card{
    border-radius:.5em;
    box-shadow: 2px 2px 30px -10px rgba(0,0,0,.2);
    padding:1em;

    .avatar{
      width:100%;
    }

    ul{
      list-style: none;

      li{
        padding:.3em 0;
      }
    }
  }

  .row_select{
    margin-bottom:1em;
  }

  .col_action{
    margin-top:1em;
  }
}


.orderExist{
  font-size:.9em;
  border-radius:.5em;
  background: #ffcd6b;
  padding:10px;
  text-align:right;
  color: #5f4922;

  strong{
    font-size:1.2em;
    font-weight:500;
  }
}
.orderSuccessMessage{
  margin-top:3em;
  color: #48c948;
  font-size:1.3em;
  text-align:center;
}


.empty{
  text-align: center;
  font-size: 1.4em;
  display: block;
  color:rgb(207, 207, 207);
}

.extraUserInfos{
  padding:1em;
  background: #FFF;
  box-sizing: border-box;
  box-shadow: 6px 30px 30px -20px rgba(0,0,0,.1);

  &.password{
    background-color: rgb(247, 247, 247);
  }

  .title{
    display: block;
    margin:.3em;
    color:grey;
  }
  .row{
    align-items: center;    
  }

  .input{
    margin:.4em 0;
  }

  .error{
    border:solid 1px red;
  }

  .error_message{
    color:red;
    text-align: center;
  }

  button{
    width:60%;
    margin-left:20%;
    margin-bottom:1em;
  }
}
.validated{
  position: fixed;
  z-index: 99;
  width:100vw;
  height:100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,.5);
  top:0;
  left:0;

  .inner{
    background-color: #FFF;
    padding: 2em;
    width:50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    span{
      font-size: 2em;
      color:#48c948;
    }
    strong{
      margin:2em 0;
    }
  }
}

.settings{
  .card{
    border:none;
    box-shadow: 1px 1px 10px -3px rgba(0,0,0,.1);
    padding:1em;
    margin:10px 0;


    > span{
      font-size:1.2em;
      color:grey;
      display: block;
      margin-bottom:1em;
      border-bottom:solid 1px #EEE;
      padding-bottom:.4em;
    }

    .newsletter{
      padding:.2em .2em .8em .2em;
      border-bottom:solid 1px #EEE;
      margin-bottom:1em;
    }
  }
}
