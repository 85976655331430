
.formCol{
  overflow-y: auto;
  height:100%;
  position:relative;

  .loginLogoApp{
    display: block;
    margin:5em auto 2em auto;
  }


  .successMessage{
    color: #46cd77;
    width:100%;
    padding:5em 15vw;
    text-align: center;

    &.registerSuccess{
      padding:0;
    }

    .linkToLogin{
      display:inline-block;
      padding:.4em 1em;
      text-decoration:none;
      border-radius:.2em;
      margin-top:1em;
      color:#FFF;
      background:$turquoise;
      border:none;
      outline:none;
      font-weight:600;
      transition:.3s;

      &:focus{
        background: $midTurquoise;
      }

      &:hover{
        background: $midTurquoise;
      }
    }
  }

}
.loginForm,.registerForm{
  height:100%;
  width:100%;
  overflow: hidden;

  .loginRow{
    background:$black;
    height:100%;
  }
  .formContainer{
    position:relative;
    padding:5em 15vw;
    color:#FFF;

    strong.title{
      margin-top:-4em;
      display:block;
      text-align: left;
      font-size: 1.1em;
      margin-bottom:1em;
    }

  }

  .loginBackgroundCol{
    background:url('../Images/cinema2.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height:100%;
  }
}

.registerForm{
  .formContainer{
    padding:5em;
  }
}

.alertError{
  position:absolute;
  bottom:0;
  left:15%;
  width:70%;
}


.login_nav{
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom:solid 1px grey;
  padding-bottom:1em;
  margin:-4em 0 2em 0;

  a{
    color:#FFF;
    text-decoration: none;
    margin:0 1em;
    transition:.3s;
    opacity: .4;
    &.active{
      opacity:1;
    }
    &:hover{
      opacity: 1;
    }
  }
}


.lostPassword{
  color:#FFF;
  text-decoration: none;
  font-size: .9em;
  text-align: right;
  margin-left:1em;
  transition:.2s;

  &:hover{
    color:$turquoise;
  }
}


.valideInfo{
  text-align:center;
  font-size:1.2em;
  padding:2em;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .success{
    color: #88e088;

    a{
      display:inline-block;
      text-decoration: none;
      background: $turquoise;
      color:#FFF;
      padding:.3em 1em;
      border-radius:.3em;
      margin-top:1em;
      transition:.3s;

      &:hover{
        background: $midTurquoise;
      }
    }
  }

  .error{
    color:red;
  }
}


.errorMessage{
  margin-top:1em;
}
