.studentForm{
    width:100vw;
    height:100vh;

  .header{ 
    width:70%;
    height:auto;
    position: absolute;
  } 

  .pageContent{
    width:100%;
    overflow-x: hidden;
    position: relative;
    background-color: #FFF;

    .cover{
      width:100%;
      height:52vh;
      //transform:skew(-10deg) rotate(-10deg);
      overflow: hidden;
    
      .inner{
        background:url('../Images/pass/header_bg.png');
        background-size: cover;
        //background-position: center;
        //background-position:0 70%;
        //transform:skew(10deg) rotate(10deg);
        width:100%;
        height:100%;
        margin-top:-1em;
      }
  
      &:after{
        content:'';
        background-color: #FFF;
      }

      @media all and (max-width:1350px){
          height:35vh;
      }

      @media all and (max-width:991px){
        transform:skew(0) rotate(0);
        height:25vh;
        .inner{
          transform:skew(0) rotate(0);
          height:100%;
        }
      }
    }
  
   
    .containerStudent{
      position: relative;
      margin-top:-20em;
      padding:0em 2em 2em 2em;
      @media all and (max-width:991px){
        max-width:90%;
        padding:1em .5em;
      }


      .cover{
        width:400px;
        height:400px;
        border-radius:300px;
        object-fit: cover;
     
        @media all and (max-width:991px){
          width: 40vw;
          height:40vw;
          margin-top:4em;
        }

        @media all and (max-width:768px){
          width: 40vw;
          height:40vw;
          margin-top:7em;
        }
      }

      .passeport{
        position: relative;
        z-index: 2;
        width:600px;
        height:auto;
        margin-top:-3em;
        //box-shadow: 1px 1px 10px -3px rgba(0,0,0,.2);

        @media all and (max-width:1450px){
          margin-top:0;
        }
        @media all and (max-width:991px){
          width:80%; 
          margin-left:20%;
          margin-top:-15em;
          //margin-bottom:2em;
        }
        @media all and (max-width:768px){
          width:80%; 
          margin-left:20%;
          margin-top:-7em;
          //margin-bottom:2em;
        }

    
      }
      .baseLine{
        font-size: 2.7em;
        display: block;
        line-height: 1.2em;
        margin-bottom:.5em;

        @media all and (max-width:991px){
          font-size:2em;
        }

        @media all and (max-width:768px){
          font-size:1.6em;
        }
      }
    

      .subBaseLine{
        font-size: 1.8em;
        display: block;

        @media all and (max-width:991px){
          font-size:1.6em;
        }

        @media all and (max-width:768px){
          font-size:1.3em;
        }
      }

      .infoPasseport{
        margin:1em 0;
        font-size: 1.2em;
        color:rgb(106, 106, 106);

        @media all and (max-width:991px){
          font-size:1.2em;
          margin:2em 0;
        }

        @media all and (max-width:768px){
          font-size:1.1em;
        }
      }

      .newsletter_infos{
        display: inline-block;
        font-style: italic;
        color:rgb(158, 158, 158);
        font-size: .8em;
        margin-left:5em;
        top:-.7em;
        position: relative;
      }

      .switchCheck{
        margin:.5em 0;  

        @media all and (max-width:991px){
          margin:.5em 0;
        }

        @media all and (max-width:1350px){
          font-size:.9em;

          .form-check-input{
            width:3em;
            height:1.6em;
            margin-left:-2em;
            margin-right:1em;
          }

          label{
            width:80%;
            margin-top:.3em;
            display: inline-block;
          }
          
        }

       
      }
      

      .colValidation{
        margin-top:2em;

        .form-check{
          display: inline-block;
          margin-right:10px;

          @media all and (max-width:1350px){
            font-size:.9em;
  
            .form-check-input{
              width:3em;
              height:1.6em;
              margin-left:-2em;
              margin-right:1em;
            }
  
            label{
              margin-top:.3em;
            }
          
          }

        }

        a{
          color:$turquoise;

          @media all and (max-width:768px){
            font-size:.9em;
            display: inline-block;
            width:80%;
            margin-left:20%;
          }
        }
      }

      .colSubmit{
        margin-top:2em;
        text-align: right;

        @media all and (max-width:1350px){
          text-align: center;

          button{
            font-size: 1.3em;
            padding:.3em 1em;
          }
        }
      }
    }
  }


  .footer{
    width:100%;
    height:25vh;
    object-fit: cover;
    margin-top:2em;
  }

}


.studentFormFields,.textForm{

  transition:.3s;

  sup{
    color:red;
    font-size: 1.3em;
    position:relative;
    top:.2em;
  }

  .remember{
    font-style: italic;
    color:rgb(189, 189, 189);
    font-size: .8em;

    @media all and (max-width:768px){
      display: block;
    }
  }

  &.show{
    opacity: 1;
    visibility: visible;
  }

  &.hide{
    opacity: 0;
    visibility: hidden;
    height:0;

  }


  @media all and (max-width:1350px){
    input{
      padding:.8em .5em;
    }
  }
}

.successMessage{
  transition:.3s;

  text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 1.5em;
    padding: 2em 0;
    color: #93c05f;

  &.show{
    opacity: 1;
    visibility: visible;
  }

  &.hide{
    opacity: 0;
    visibility: hidden;
    height:0;
    padding:0;
  }
}

.loginInformations{

  .passwordForm,.loginBox{
    transition:.3s;

    &.hide{
      opacity: 0;
      visibility: hidden;
      height:0;
    }
  }

  .loginBox,.passwordForm{
    background-color: rgb(240, 240, 240);
    padding:1em;
    border-radius: .3em;
    box-sizing: border-box;
    text-align: center;

    ul{
      list-style: none;
      padding:0;
      margin:2em 0;
      li{
        background-color: #FFF;
        border-radius: .3em;
        padding:.3em;
        margin:.3em 0;
        text-align: left;
        strong{
          color:$turquoise;
        }

        span{
          font-style: italic;
          color:rgb(190, 190, 190);
        }
      }
    }

    .linkToLogin{
      display: inline-block;
      background-color: $turquoise;
      color:#FFF;
      text-decoration: none;
      padding:.3em 1em;
      text-align: center;
      border-radius: .3em;
      transition:.3s;
    
      &:hover{
        background-color: $black;
      }

    
    }

    .formBasicPassword{
      margin-top:1em;
    }
  }
}

.errorField{
  text-align: center;
  strong{
    font-size: 2em;
  }

  span{
    display: block;
  }
}

.dateField{
  transition:.3s;

  &.wrong{
    border-color: red;
  }
}

.errorPassword{
  display:block;
  text-align: center;
  color:red;
  margin-bottom:1em;
}