@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;600;800&display=swap');

@import 'variables';
@import 'login';
@import 'header';
@import 'dashboard';
@import 'studentForm';

html,body{
  width:100vw;
  height:100vh;
  margin:0;
  overflow:hidden;
}

body{
  font-family: 'Raleway', sans-serif;
}

.App{
  width:100vw;
  height:100vh;
  overflow-y: auto;

  .AppContainer{
    width:100vw;
    height:100vh;
    overflow-y: auto;
  }
}

button.btn-primary{
  background:$turquoise;
  border:none;
  outline:none;
  font-weight:600;

  &:disabled{
    background:$turquoise;
    background-color:$turquoise;
  }
  &:focus{
    background: $midTurquoise;
  }

  &:hover{
    background: $midTurquoise;
  }
}


.lost{
  background: black;
  position:relative;
  width:100vw;
  height:100vh;
  color:#FFF;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  strong{
    font-size:4em;
  }
}
