@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;600;800&display=swap");
.formCol {
  overflow-y: auto;
  height: 100%;
  position: relative;
}
.formCol .loginLogoApp {
  display: block;
  margin: 5em auto 2em auto;
}
.formCol .successMessage {
  color: #46cd77;
  width: 100%;
  padding: 5em 15vw;
  text-align: center;
}
.formCol .successMessage.registerSuccess {
  padding: 0;
}
.formCol .successMessage .linkToLogin {
  display: inline-block;
  padding: 0.4em 1em;
  text-decoration: none;
  border-radius: 0.2em;
  margin-top: 1em;
  color: #FFF;
  background: #008683;
  border: none;
  outline: none;
  font-weight: 600;
  transition: 0.3s;
}
.formCol .successMessage .linkToLogin:focus {
  background: #5ea5a3;
}
.formCol .successMessage .linkToLogin:hover {
  background: #5ea5a3;
}

.loginForm, .registerForm {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.loginForm .loginRow, .registerForm .loginRow {
  background: #1d1d1b;
  height: 100%;
}
.loginForm .formContainer, .registerForm .formContainer {
  position: relative;
  padding: 5em 15vw;
  color: #FFF;
}
.loginForm .formContainer strong.title, .registerForm .formContainer strong.title {
  margin-top: -4em;
  display: block;
  text-align: left;
  font-size: 1.1em;
  margin-bottom: 1em;
}
.loginForm .loginBackgroundCol, .registerForm .loginBackgroundCol {
  background: url("../Images/cinema2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
}

.registerForm .formContainer {
  padding: 5em;
}

.alertError {
  position: absolute;
  bottom: 0;
  left: 15%;
  width: 70%;
}

.login_nav {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px grey;
  padding-bottom: 1em;
  margin: -4em 0 2em 0;
}
.login_nav a {
  color: #FFF;
  text-decoration: none;
  margin: 0 1em;
  transition: 0.3s;
  opacity: 0.4;
}
.login_nav a.active {
  opacity: 1;
}
.login_nav a:hover {
  opacity: 1;
}

.lostPassword {
  color: #FFF;
  text-decoration: none;
  font-size: 0.9em;
  text-align: right;
  margin-left: 1em;
  transition: 0.2s;
}
.lostPassword:hover {
  color: #008683;
}

.valideInfo {
  text-align: center;
  font-size: 1.2em;
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.valideInfo .success {
  color: #88e088;
}
.valideInfo .success a {
  display: inline-block;
  text-decoration: none;
  background: #008683;
  color: #FFF;
  padding: 0.3em 1em;
  border-radius: 0.3em;
  margin-top: 1em;
  transition: 0.3s;
}
.valideInfo .success a:hover {
  background: #5ea5a3;
}
.valideInfo .error {
  color: red;
}

.errorMessage {
  margin-top: 1em;
}

header {
  background: #1d1d1b;
  padding: 1em;
}
header .header_logo {
  width: 10em;
}

.headerRow {
  align-items: center;
}

.menu {
  text-align: right;
}
.menu a {
  color: #FFF;
  text-decoration: none;
  margin: 0 1em;
}
@media all and (max-width: 768px) {
  .menu {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.9);
    z-index: 3;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    text-align: right;
    padding: 5em 2em;
    transition: 0.3s;
    transform: translateX(100vw);
  }
  .menu a {
    margin: 1em 0;
    font-size: 1.2em;
  }
  .menu button {
    margin-top: 2em;
  }
  .menu.open {
    transform: translateX(0);
  }
}

.toggle_menu {
  position: absolute;
  left: 85vw;
  z-index: 4;
}
.toggle_menu span {
  display: block;
  width: 1em;
  height: 2px;
  background: #FFF;
  margin: 0.2em 0;
  position: relative;
  transition: 0.3s;
}
.toggle_menu.open span:first-child {
  transform: rotate(45deg);
  top: 2px;
}
.toggle_menu.open span:last-child {
  transform: rotate(-45deg);
  top: -3px;
}
.toggle_menu.open span:nth-child(2) {
  display: none;
}
@media all and (min-width: 767px) {
  .toggle_menu {
    display: none;
  }
}

.dashboard_content {
  padding: 3em 0;
}
.dashboard_content .react-tabs__tab-panel {
  padding-top: 2em;
}

.dashboard_generals, .dashboard_orders {
  padding: 3em 0;
}
@media all and (max-width: 768px) {
  .dashboard_generals, .dashboard_orders {
    padding: 3em 1em;
  }
}
.dashboard_generals .row_title, .dashboard_orders .row_title {
  margin-bottom: 2em;
  background-color: #FFF;
  box-shadow: 6px 6px 10px -3px rgba(0, 0, 0, 0.1);
  padding: 0.3em;
  border-radius: 0.3em;
}
.dashboard_generals .col_action, .dashboard_orders .col_action {
  margin-top: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dashboard_generals .row_avatar, .dashboard_orders .row_avatar {
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.dashboard_generals .row_avatar .avatar, .dashboard_orders .row_avatar .avatar {
  width: 10em;
  height: 10em;
  -o-object-fit: contain;
     object-fit: contain;
  margin-bottom: 1em;
  border: solid 2px #ececec;
  border-radius: 0.4em;
}
.dashboard_generals .row_avatar .upload_avatar_input, .dashboard_orders .row_avatar .upload_avatar_input {
  display: none;
}
.dashboard_generals .row_avatar .upload_avatar_button, .dashboard_orders .row_avatar .upload_avatar_button {
  max-width: 10em;
}
@media all and (max-width: 768px) {
  .dashboard_generals .row_avatar, .dashboard_orders .row_avatar {
    margin-bottom: 2em;
  }
}
.dashboard_generals .row_label_title, .dashboard_orders .row_label_title {
  font-size: 1.3em;
  margin-bottom: 1em;
}
.dashboard_generals .pass_request, .dashboard_orders .pass_request {
  padding: 1em;
  background: #FFF;
  box-shadow: 6px 6px 10px -3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.dashboard_generals .pass_request .thumb, .dashboard_orders .pass_request .thumb {
  width: 25%;
  background-color: rgb(237, 237, 237);
  border-radius: 0.3em;
  padding: 1em 0.3em;
}
.dashboard_generals .pass_request .thumb img, .dashboard_orders .pass_request .thumb img {
  width: 100%;
  height: 8em;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard_generals .pass_request .infos, .dashboard_orders .pass_request .infos {
  padding-left: 2em;
  width: 65%;
  display: flex;
  flex-direction: column;
}
.dashboard_generals .pass_request a, .dashboard_orders .pass_request a {
  background: #008683;
  text-decoration: none;
  padding: 0.3em 1em;
  color: #FFF;
  border-radius: 0.3em;
  display: inline-block;
  text-align: center;
}

.dashboard_orders .row_sign {
  padding: 0 1em;
}
.dashboard_orders .sigCanvas {
  border: solid 2px #d9d9d9;
}
.dashboard_orders .preview_card {
  border-radius: 0.5em;
  box-shadow: 2px 2px 30px -10px rgba(0, 0, 0, 0.2);
  padding: 1em;
}
.dashboard_orders .preview_card .avatar {
  width: 100%;
}
.dashboard_orders .preview_card ul {
  list-style: none;
}
.dashboard_orders .preview_card ul li {
  padding: 0.3em 0;
}
.dashboard_orders .row_select {
  margin-bottom: 1em;
}
.dashboard_orders .col_action {
  margin-top: 1em;
}

.orderExist {
  font-size: 0.9em;
  border-radius: 0.5em;
  background: #ffcd6b;
  padding: 10px;
  text-align: right;
  color: #5f4922;
}
.orderExist strong {
  font-size: 1.2em;
  font-weight: 500;
}

.orderSuccessMessage {
  margin-top: 3em;
  color: #48c948;
  font-size: 1.3em;
  text-align: center;
}

.empty {
  text-align: center;
  font-size: 1.4em;
  display: block;
  color: rgb(207, 207, 207);
}

.extraUserInfos {
  padding: 1em;
  background: #FFF;
  box-sizing: border-box;
  box-shadow: 6px 30px 30px -20px rgba(0, 0, 0, 0.1);
}
.extraUserInfos.password {
  background-color: rgb(247, 247, 247);
}
.extraUserInfos .title {
  display: block;
  margin: 0.3em;
  color: grey;
}
.extraUserInfos .row {
  align-items: center;
}
.extraUserInfos .input {
  margin: 0.4em 0;
}
.extraUserInfos .error {
  border: solid 1px red;
}
.extraUserInfos .error_message {
  color: red;
  text-align: center;
}
.extraUserInfos button {
  width: 60%;
  margin-left: 20%;
  margin-bottom: 1em;
}

.validated {
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}
.validated .inner {
  background-color: #FFF;
  padding: 2em;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.validated .inner span {
  font-size: 2em;
  color: #48c948;
}
.validated .inner strong {
  margin: 2em 0;
}

.settings .card {
  border: none;
  box-shadow: 1px 1px 10px -3px rgba(0, 0, 0, 0.1);
  padding: 1em;
  margin: 10px 0;
}
.settings .card > span {
  font-size: 1.2em;
  color: grey;
  display: block;
  margin-bottom: 1em;
  border-bottom: solid 1px #EEE;
  padding-bottom: 0.4em;
}
.settings .card .newsletter {
  padding: 0.2em 0.2em 0.8em 0.2em;
  border-bottom: solid 1px #EEE;
  margin-bottom: 1em;
}

.studentForm {
  width: 100vw;
  height: 100vh;
}
.studentForm .header {
  width: 70%;
  height: auto;
  position: absolute;
}
.studentForm .pageContent {
  width: 100%;
  overflow-x: hidden;
  position: relative;
  background-color: #FFF;
}
.studentForm .pageContent .cover {
  width: 100%;
  height: 52vh;
  overflow: hidden;
}
.studentForm .pageContent .cover .inner {
  background: url("../Images/pass/header_bg.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  margin-top: -1em;
}
.studentForm .pageContent .cover:after {
  content: "";
  background-color: #FFF;
}
@media all and (max-width: 1350px) {
  .studentForm .pageContent .cover {
    height: 35vh;
  }
}
@media all and (max-width: 991px) {
  .studentForm .pageContent .cover {
    transform: skew(0) rotate(0);
    height: 25vh;
  }
  .studentForm .pageContent .cover .inner {
    transform: skew(0) rotate(0);
    height: 100%;
  }
}
.studentForm .pageContent .containerStudent {
  position: relative;
  margin-top: -20em;
  padding: 0em 2em 2em 2em;
}
@media all and (max-width: 991px) {
  .studentForm .pageContent .containerStudent {
    max-width: 90%;
    padding: 1em 0.5em;
  }
}
.studentForm .pageContent .containerStudent .cover {
  width: 400px;
  height: 400px;
  border-radius: 300px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 991px) {
  .studentForm .pageContent .containerStudent .cover {
    width: 40vw;
    height: 40vw;
    margin-top: 4em;
  }
}
@media all and (max-width: 768px) {
  .studentForm .pageContent .containerStudent .cover {
    width: 40vw;
    height: 40vw;
    margin-top: 7em;
  }
}
.studentForm .pageContent .containerStudent .passeport {
  position: relative;
  z-index: 2;
  width: 600px;
  height: auto;
  margin-top: -3em;
}
@media all and (max-width: 1450px) {
  .studentForm .pageContent .containerStudent .passeport {
    margin-top: 0;
  }
}
@media all and (max-width: 991px) {
  .studentForm .pageContent .containerStudent .passeport {
    width: 80%;
    margin-left: 20%;
    margin-top: -15em;
  }
}
@media all and (max-width: 768px) {
  .studentForm .pageContent .containerStudent .passeport {
    width: 80%;
    margin-left: 20%;
    margin-top: -7em;
  }
}
.studentForm .pageContent .containerStudent .baseLine {
  font-size: 2.7em;
  display: block;
  line-height: 1.2em;
  margin-bottom: 0.5em;
}
@media all and (max-width: 991px) {
  .studentForm .pageContent .containerStudent .baseLine {
    font-size: 2em;
  }
}
@media all and (max-width: 768px) {
  .studentForm .pageContent .containerStudent .baseLine {
    font-size: 1.6em;
  }
}
.studentForm .pageContent .containerStudent .subBaseLine {
  font-size: 1.8em;
  display: block;
}
@media all and (max-width: 991px) {
  .studentForm .pageContent .containerStudent .subBaseLine {
    font-size: 1.6em;
  }
}
@media all and (max-width: 768px) {
  .studentForm .pageContent .containerStudent .subBaseLine {
    font-size: 1.3em;
  }
}
.studentForm .pageContent .containerStudent .infoPasseport {
  margin: 1em 0;
  font-size: 1.2em;
  color: rgb(106, 106, 106);
}
@media all and (max-width: 991px) {
  .studentForm .pageContent .containerStudent .infoPasseport {
    font-size: 1.2em;
    margin: 2em 0;
  }
}
@media all and (max-width: 768px) {
  .studentForm .pageContent .containerStudent .infoPasseport {
    font-size: 1.1em;
  }
}
.studentForm .pageContent .containerStudent .newsletter_infos {
  display: inline-block;
  font-style: italic;
  color: rgb(158, 158, 158);
  font-size: 0.8em;
  margin-left: 5em;
  top: -0.7em;
  position: relative;
}
.studentForm .pageContent .containerStudent .switchCheck {
  margin: 0.5em 0;
}
@media all and (max-width: 991px) {
  .studentForm .pageContent .containerStudent .switchCheck {
    margin: 0.5em 0;
  }
}
@media all and (max-width: 1350px) {
  .studentForm .pageContent .containerStudent .switchCheck {
    font-size: 0.9em;
  }
  .studentForm .pageContent .containerStudent .switchCheck .form-check-input {
    width: 3em;
    height: 1.6em;
    margin-left: -2em;
    margin-right: 1em;
  }
  .studentForm .pageContent .containerStudent .switchCheck label {
    width: 80%;
    margin-top: 0.3em;
    display: inline-block;
  }
}
.studentForm .pageContent .containerStudent .colValidation {
  margin-top: 2em;
}
.studentForm .pageContent .containerStudent .colValidation .form-check {
  display: inline-block;
  margin-right: 10px;
}
@media all and (max-width: 1350px) {
  .studentForm .pageContent .containerStudent .colValidation .form-check {
    font-size: 0.9em;
  }
  .studentForm .pageContent .containerStudent .colValidation .form-check .form-check-input {
    width: 3em;
    height: 1.6em;
    margin-left: -2em;
    margin-right: 1em;
  }
  .studentForm .pageContent .containerStudent .colValidation .form-check label {
    margin-top: 0.3em;
  }
}
.studentForm .pageContent .containerStudent .colValidation a {
  color: #008683;
}
@media all and (max-width: 768px) {
  .studentForm .pageContent .containerStudent .colValidation a {
    font-size: 0.9em;
    display: inline-block;
    width: 80%;
    margin-left: 20%;
  }
}
.studentForm .pageContent .containerStudent .colSubmit {
  margin-top: 2em;
  text-align: right;
}
@media all and (max-width: 1350px) {
  .studentForm .pageContent .containerStudent .colSubmit {
    text-align: center;
  }
  .studentForm .pageContent .containerStudent .colSubmit button {
    font-size: 1.3em;
    padding: 0.3em 1em;
  }
}
.studentForm .footer {
  width: 100%;
  height: 25vh;
  -o-object-fit: cover;
     object-fit: cover;
  margin-top: 2em;
}

.studentFormFields, .textForm {
  transition: 0.3s;
}
.studentFormFields sup, .textForm sup {
  color: red;
  font-size: 1.3em;
  position: relative;
  top: 0.2em;
}
.studentFormFields .remember, .textForm .remember {
  font-style: italic;
  color: rgb(189, 189, 189);
  font-size: 0.8em;
}
@media all and (max-width: 768px) {
  .studentFormFields .remember, .textForm .remember {
    display: block;
  }
}
.studentFormFields.show, .textForm.show {
  opacity: 1;
  visibility: visible;
}
.studentFormFields.hide, .textForm.hide {
  opacity: 0;
  visibility: hidden;
  height: 0;
}
@media all and (max-width: 1350px) {
  .studentFormFields input, .textForm input {
    padding: 0.8em 0.5em;
  }
}

.successMessage {
  transition: 0.3s;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.5em;
  padding: 2em 0;
  color: #93c05f;
}
.successMessage.show {
  opacity: 1;
  visibility: visible;
}
.successMessage.hide {
  opacity: 0;
  visibility: hidden;
  height: 0;
  padding: 0;
}

.loginInformations .passwordForm, .loginInformations .loginBox {
  transition: 0.3s;
}
.loginInformations .passwordForm.hide, .loginInformations .loginBox.hide {
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.loginInformations .loginBox, .loginInformations .passwordForm {
  background-color: rgb(240, 240, 240);
  padding: 1em;
  border-radius: 0.3em;
  box-sizing: border-box;
  text-align: center;
}
.loginInformations .loginBox ul, .loginInformations .passwordForm ul {
  list-style: none;
  padding: 0;
  margin: 2em 0;
}
.loginInformations .loginBox ul li, .loginInformations .passwordForm ul li {
  background-color: #FFF;
  border-radius: 0.3em;
  padding: 0.3em;
  margin: 0.3em 0;
  text-align: left;
}
.loginInformations .loginBox ul li strong, .loginInformations .passwordForm ul li strong {
  color: #008683;
}
.loginInformations .loginBox ul li span, .loginInformations .passwordForm ul li span {
  font-style: italic;
  color: rgb(190, 190, 190);
}
.loginInformations .loginBox .linkToLogin, .loginInformations .passwordForm .linkToLogin {
  display: inline-block;
  background-color: #008683;
  color: #FFF;
  text-decoration: none;
  padding: 0.3em 1em;
  text-align: center;
  border-radius: 0.3em;
  transition: 0.3s;
}
.loginInformations .loginBox .linkToLogin:hover, .loginInformations .passwordForm .linkToLogin:hover {
  background-color: #1d1d1b;
}
.loginInformations .loginBox .formBasicPassword, .loginInformations .passwordForm .formBasicPassword {
  margin-top: 1em;
}

.errorField {
  text-align: center;
}
.errorField strong {
  font-size: 2em;
}
.errorField span {
  display: block;
}

.dateField {
  transition: 0.3s;
}
.dateField.wrong {
  border-color: red;
}

.errorPassword {
  display: block;
  text-align: center;
  color: red;
  margin-bottom: 1em;
}

html, body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

body {
  font-family: "Raleway", sans-serif;
}

.App {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}
.App .AppContainer {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

button.btn-primary {
  background: #008683;
  border: none;
  outline: none;
  font-weight: 600;
}
button.btn-primary:disabled {
  background: #008683;
  background-color: #008683;
}
button.btn-primary:focus {
  background: #5ea5a3;
}
button.btn-primary:hover {
  background: #5ea5a3;
}

.lost {
  background: black;
  position: relative;
  width: 100vw;
  height: 100vh;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.lost strong {
  font-size: 4em;
}/*# sourceMappingURL=main.css.map */