header{
background:$black;
  padding:1em;

  .header_logo{
    width:10em;
  }
}


.headerRow{
  align-items: center;
}
.menu{
  text-align:right;
  a{
    color:#FFF;
    text-decoration: none;
    margin:0 1em;
  }

  @media all and (max-width:768px){
    position:fixed;
    height:100vh;
    width:100vw;
    background: rgba(0,0,0,.9);
    z-index:3;
    top:0;
    left:0;
    display:flex;
    flex-direction:column;
    justify-content: flex-start;
    align-items: flex-end;
    text-align:right;
    padding:5em 2em;
    transition:.3s;

    transform:translateX(100vw);


    a{
      margin:1em 0;
      font-size:1.2em;
    }

    button{
      margin-top:2em;
    }

    &.open{
      transform:translateX(0);
    }
  }
}

.toggle_menu{
  position:absolute;
  left:85vw;
  z-index: 4;
  span{
    display:block;
    width:1em;
    height:2px;
    background:#FFF;
    margin:.2em 0;
    position:relative;
    transition:.3s;
  }

  &.open{
    span{

      &:first-child{
        transform:rotate(45deg);
        top:2px;
      }
      &:last-child{
        transform:rotate(-45deg);
        top:-3px;
      }

      &:nth-child(2){
        display:none;
      }
    }
  }

  @media all and (min-width:767px) {
    display:none;
  }
}
